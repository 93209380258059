/** Set active company code */
export const setActiveCompany  = async(data) =>{
  sessionStorage.setItem('activeCompany', data);
  return true;
}

/** Get active company code */
export function getActiveCompany(){  
    const storageData = sessionStorage.getItem('activeCompany');
    return storageData;
}

/** Flush the session */
export const flushAuth = async() => {
  sessionStorage.removeItem('activeCompany');
  sessionStorage.removeItem('authInfo');
  return false;
}

/** Check storage exist or not */
export function issetLocalAuth() {
  return (sessionStorage.getItem('authInfo'))? true : false;
}

/** Set user data */
export const setAuthInfo  = async(data) =>{
  sessionStorage.setItem('authInfo', data? JSON.stringify(data) : '');
  return true;
}

/** Set user data */
export function getAuthInfo() {  
  return JSON.parse(sessionStorage.getItem('authInfo'));
}
import { createSlice } from "@reduxjs/toolkit";
import { getCompanyAnnouncementByDateAction } from "../actions/announcementAction";

const initialState = {
  data: [],
  loading: false,
  error: null,
};

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
   
     /** TODAY */
     builder.addCase(getCompanyAnnouncementByDateAction.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getCompanyAnnouncementByDateAction.fulfilled, (state, action) => {
      state.data = action?.payload?.data?.company?.pressReleases?.nodes;
      state.loading = false;
    });
    builder.addCase(getCompanyAnnouncementByDateAction.rejected, (state, action) => {
      state.fetchError = action.error;
      state.loading = false;
    });
    
  },
});

export default announcementSlice;

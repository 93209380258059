import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTodayWidgetInfo_1,
  getTodayWidgetInfo_2,
  getTodayWidgetInfo_3,
  getWeeklyWidgetInfo,
  getMonthlyWidgetInfo,
  getCompanyAnnouncement,
} from "../../services";
import { selectPrimaryMarketIds } from "../slice/company";
import _ from "lodash";

/** USED TO GET TODAY WIDGET CARDS */
export const getTodayWidgetInfoAction = createAsyncThunk(
  "getInstrument",
  async (params, { getState }) => {
    // const response1 = await getTodayWidgetInfo_1(params);
    // const response2 = await getTodayWidgetInfo_2(params);
    // const response3 = await getTodayWidgetInfo_3(params);
    // const data = response.data;

    const [response_1, response_2, response_3] = await Promise.all([
      getTodayWidgetInfo_1(params),
      getTodayWidgetInfo_2(params),
      getTodayWidgetInfo_3(params),
    ]);
    const data = _.merge({}, response_1.data, response_2.data, response_3.data);
    const state = getState();
    const primaryMarketIds = selectPrimaryMarketIds(state);

    return { data, primaryMarketIds };
  }
);

/** USED TO GET WEEKLY WIDGET CARDS */
export const getWeeklyWidgetInfoAction = createAsyncThunk(
  "getInstrument",
  async (params, { getState }) => {
    const response = await getWeeklyWidgetInfo(params);
    const data = response.data;
    const state = getState();
    const primaryMarketIds = selectPrimaryMarketIds(state);

    return { data, primaryMarketIds };
  }
);

/** USED TO GET MONTHLY WIDGET CARDS */
export const getMonthlyWidgetInfoAction = createAsyncThunk(
  "getInstrument",
  async (params, { getState }) => {
    const response = await getMonthlyWidgetInfo(params);
    const data = response.data;
    const state = getState();
    const primaryMarketIds = selectPrimaryMarketIds(state);

    return { data, primaryMarketIds };
  }
);

/** USED TO GET COMPANY ANNOUNCEMENT WIDGET */
export const getCompanyAnnouncementAction = createAsyncThunk(
  "getCompanyAnnouncement",
  async (params) => {
    const response = await getCompanyAnnouncement(params);
    const data = response.data;
    return { data };
  }
);

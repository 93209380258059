import React from 'react';
import { ToastNotification } from '@carbon/react';
import { useSelector, useDispatch } from 'react-redux';
import { hideToast } from '../../store/slice/toast';

const ToastDialog = () => {
  const { title, message, hint, variant, showToast } = useSelector((state) => state.toast);
  
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(hideToast());
  };

  return (    
    <>
    {showToast && (
      <ToastNotification      
        className='toast-container'
        kind={variant.toLowerCase()}
        title={message || undefined}
        onClose={handleClose}
        timeout={3000}
        // hideCloseButton={true}
      />
    )} 
    </>   
  );
};

export default ToastDialog;
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCompanyAnnouncementByDate } from "../../services";

/** USED TO GET COMPANY ANNOUNCEMENT BY DATE */
export const getCompanyAnnouncementByDateAction = createAsyncThunk(
  "getCompanyAnnouncementByDate",
  async (params) => {
    const response = await getCompanyAnnouncementByDate(params);
    return response.data;
  }
);
import api from "./api";
import authAPI from "./authAPI";
import {
  SIGNUP_USER,
  LOGIN_USER,
  GET_COMPANY_INFO,
  GET_TODAY_WIDGET_INFO_1,
  GET_TODAY_WIDGET_INFO_2,
  GET_TODAY_WIDGET_INFO_3,
  GET_WEEKLY_WIDGET_INFO,
  GET_MONTHLY_WIDGET_INFO,
  GET_COMPANY_ANNOUNCEMENT,
  GET_ANNOUNCEMENT_BY_DATE,
  GET_ANNOUNCEMENT_BY_ID,
  GET_ANNOUNCEMENT_QA,
  UPDATE_ANNOUNCEMENT_QA,
  UPDATE_ANNOUNCEMENT_LIKE,
  GET_FINANCIAL_REPORT,
} from "./query";

/**
 *
 * @param {Record<string, any>} params
 * @param {number} apiVersion
 * @returns {Promise<{
 *  data: {Record<string, any>}
 * }>}
 */

export const signUp = (props) =>
  authAPI.POST(null, { query: SIGNUP_USER(props) });
export const login = (props) =>
  authAPI.POST(null, { query: LOGIN_USER(props) });

export const getCompanyInfo = (param) => {
  return api.POST(null, { query: GET_COMPANY_INFO(param) });
};

/** GET TODAY WIDGET INFORMATION*/
export const getTodayWidgetInfo_1 = (param) => {
  return api.POST(null, { query: GET_TODAY_WIDGET_INFO_1(param) });
};
export const getTodayWidgetInfo_2 = (param) => {
  return api.POST(null, { query: GET_TODAY_WIDGET_INFO_2(param) });
};
export const getTodayWidgetInfo_3 = (param) => {
  return api.POST(null, { query: GET_TODAY_WIDGET_INFO_3(param) });
};

/** GET WEEKLY WIDGET INFORMATION */
export const getWeeklyWidgetInfo = (param) => {
  return api.POST(null, { query: GET_WEEKLY_WIDGET_INFO(param) });
};

/** GET MONTHLY WIDGET INFORMATION */
export const getMonthlyWidgetInfo = (param) => {
  return api.POST(null, { query: GET_MONTHLY_WIDGET_INFO(param) });
};

/** GET COMPANY ANNOUNCEMENT INFORMATION */
export const getCompanyAnnouncement = (param) => {
  return api.POST(null, {
    query: `query {${GET_COMPANY_ANNOUNCEMENT(param,10)}}`,
  });
};

/** GET COMPANY ANNOUNCEMENT INFORMATION BY DATE */
export const getCompanyAnnouncementByDate = (param) => {
  return api.POST(null, {
    query: `query {${GET_ANNOUNCEMENT_BY_DATE(param)}}`,
  });
};

/** GET COMPANY ANNOUNCEMENT DETAIL */
export const getCompanyAnnouncementDetail = (param) => {
  return api.POST(null, { query: GET_ANNOUNCEMENT_BY_ID(param) });
};

/** GET COMPANY ANNOUNCEMENT QA */
export const getCompanyAnnouncementQA = (param) => {
  return authAPI.POST(null, { query: GET_ANNOUNCEMENT_QA(param) });
};

/** UPDATE COMPANY ANNOUNCEMENT QA */
export const updateCompanyAnnouncementQA = (param) => {
  return authAPI.POST(null, { query: UPDATE_ANNOUNCEMENT_QA(param) });
};

/** UPDATE COMPANY ANNOUNCEMENT LIKE */
export const updateCompanyAnnouncementLike = (param) => {
  return authAPI.POST(null, { query: UPDATE_ANNOUNCEMENT_LIKE(param) });
};

/** GET FINANCIAL REPORT */
export const getFinancialReports = (param) => {
  return api.POST(null, { query: GET_FINANCIAL_REPORT(param) });
};

import { createSelector, createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeUser: {
    data: {
      details: null
    },
    loading: false,
    error: null,
  },
}
export const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setAuthAction: (state, action) => {
      state.activeUser.data.details = action.payload;
    },
    setLogoutAction: (state, action) => {
      state.activeUser.data.details = null;
    },
  },
});
export const { setAuthAction, setLogoutAction } = authSlice.actions;
export default authSlice;

export const authSelector = createSelector(
  [
    state => state.auth.activeUser.data,
    state => state.auth.activeUser.loading,
    state => state.auth.activeUser.error,
  ],
  (data, loading, error) => ({
    data,
    loading,
    error
  })
);
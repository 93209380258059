import { lazy, Suspense, useState } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/index.scss";

/** LAYOUTS */
const DefaultLayout = lazy(() =>
  import("./components/layout/default-layout.component")
);

/** PAGES */
const Home = lazy(() => import("./pages/Home"));
const Announcement = lazy(() => import("./pages/Announcement"));
const AnnouncementDetail = lazy(() => import("./pages/AnnouncementDetail"));
const FinancialReports = lazy(() => import("./pages/FinancialReports"));

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<DefaultLayout companycode=":companycode" />}>
          {/* Render the Home component for the root path */}
          <Route index element={<Home />} />
          {/* Enforce the presence of the companycode parameter with a regex pattern */}
          <Route
            path=":companycode:model:count:demo"
            element={<Home />}
            caseSensitive={true}
          />
          <Route path="announcement">
            <Route path="" element={<Announcement />} />
            <Route path=":id" element={<AnnouncementDetail />} />
          </Route>
          <Route path="financial-reports" element={<FinancialReports />} />
        </Route>
        {/* <Route
          path="/login"
          element={<Login setIsAuthenticated={setIsAuthenticated} />}
        /> */}
      </Routes>
    </Suspense>
  );
}

export default App;

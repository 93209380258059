import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCompanyInfo } from "../../services";

export const setWiseAppConfig = (config) => ({
  type: "SET_WISE_APP_CONFIG",
  payload: config,
});

export const setSearchHistory = (history) => ({
  type: "SET_SEARCH_HISTORY",
  payload: history,
});

/** USED TO GET COMPANY INFORMATION */
export const getCompanyInfoAction = createAsyncThunk(
  "getCompanyInfo",
  async (params) => {
    const response = await getCompanyInfo(params);
    return response.data;
  }
);

import { getDateRanges, getYearsArray } from "../utils/common";

export const GET_COMPANY_INFO = (param) => {
  return `query {
    company(code: "${param}") {
      id
      code
      country
      customerTypeId
      email
      fax
      homePage
      marketId
      name
      tel
      town
      primaryMarket {
        abbreviation
        businessDaysStoT
        cityId
        closeTimeLocal
        delay
        id
        openTimeLocal
        parentId
        regionId
        timeDiff
        timezoneId
        timezoneName
        translationId
        status {
          isOpened
          remainingTime
        }
        timezone {
          name
          nameIANA
        }
      }
    }
  }`;
};

/** INTRADAY INFORMATION */
export const INTRADAY_QUERY = (duration = 1, dateRange) => {
  return `
    intraday(
            timeIntervalGrouping: ${duration}
            order: { dateTime: ASC }
            useCloud: true
            where: { dateTime: ${dateRange} }
      ) {
      nodes {
        close
        dateTime
      }
    }
  `;
};

/** HISTORICAL INFORMATION */
export const HISTORICALS_QUERY = (dateRange, type) => {
  const whereClause = dateRange ? `where: { dateTime: ${dateRange} }` : "";
  const orderClause = `order: { dateTime: ${
    type === "week" ? "DESC" : "ASC"
  } } ${type === "week" ? ", first: 5" : ", first: 32000"}`;
  return `
      historicals(
        ${whereClause}
        ${orderClause}
      ) {
        nodes {
          close
          dateTime
        }
      }
  `;
};

/** CRAFT PEERS CHART QUERY */
export const GET_PEERS_QUERY = (param, dateRange) => {
  return `
    peersComparison: ${GET_PEERS_COMPARISON(param, dateRange)}
    allPeers: ${GET_ALL_PEERS(param, dateRange)}
  `;
};
/** PEERS COMPARISON */
export const GET_PEERS_COMPARISON = (param, dateRange) => {
  return `
  instrumentByIds(ids: [${param?.accessConfig?.primaryMarket},${
    param?.accessConfig?.peers
  }], useCloud: true) {
    id
    shareName
    currencyCode
    currentPrice {
      change
      changePercentage
      last
      high
      open
      low
      prevClose
      volume
    }
    market {
      abbreviation
      status {
        isOpened
        remainingTime
      }
      openTimeLocal
      closeTimeLocal
      businessDaysStoT
      timezoneName
      timeDiff
      timezone {
        diffFromServerTime
        translation {
          value
        }
        diff
        name
        nameIANA
      }
      city {
        name
      }
    }
    day: ${INTRADAY_QUERY(1, dateRange?.yesterDayRange)}
    week: ${HISTORICALS_QUERY(dateRange?.last30DaysRange, "week")}
    month: ${HISTORICALS_QUERY(dateRange?.last30DaysRange)}
    threemonth: ${HISTORICALS_QUERY(dateRange?.last3MonthsRange)}
    sixmonth: ${HISTORICALS_QUERY(dateRange?.last6MonthsRange)}
    ytd: ${HISTORICALS_QUERY(dateRange?.currentYearRange)}
    oneyear: ${HISTORICALS_QUERY(dateRange?.last12MonthsRange)}
    fiveyear: ${HISTORICALS_QUERY(dateRange?.last5YearsRange)}
    max: ${HISTORICALS_QUERY("")}    
  }`;
};

/** GET ALL PEERS */
export const GET_ALL_PEERS = (param, dateRanges) => {
  return `
  instrumentByIds(
    ids: [${param?.accessConfig?.peers}]
    useCloud: true
  ) {
    id
    shareName
    companyCode
    currencyCode
    symbol
    currentPrice(useCloud: true) {
      change
      changePercentage
      last
      high
      open
      low
      prevClose
    }
      market {
        abbreviation
        status {
          isOpened
          remainingTime
        }
        openTimeLocal
        closeTimeLocal
        businessDaysStoT
        timezoneName
        timeDiff
        timezone {
          diffFromServerTime
          translation {
            value
          }
          diff
          name
          nameIANA
        }
        city {
          name
        }
      }
    ${INTRADAY_QUERY(1, dateRanges?.todayRange)}
  }`;
};

/** DIVIDEND */
export const GET_DIVIDENDS = (ids, year) => {
  return `
  instrumentByIds(ids: [${ids}]) {
    id
    shareName
    companyCode
    currencyCode
    symbol
    company {
      country
    }
    market {
      abbreviation
      status {
        isOpened
      }
      city {
        name
      }
    }
    annualDividends(order:{year :ASC} where: {year:{gte:${year - 5}}}) {     
        year,
        yield,
        total
    }
    dividends(order: { exDate: DESC }, first: 1) {
      nodes {
        instrumentId       
        exDate 
        fyeDate
        payDate
        recDate   
        period
        grossDivAdj    
      }
    }
  }`;
};

//  INTRADAY MAIN QUERY
export const GET_INTRADAY_MAIN = (ids, dateRange) => {
  return `instrumentByIds(ids: [${ids}]) {
      id
      shareName
      companyCode
      currencyCode
      symbol
      company {
        country
      }
      currentPrice {
        change
        changePercentage
        last
        high
        open
        low
        prevClose
        volume
      }
      market {
        abbreviation
        status {
          isOpened
          remainingTime
        }
        openTimeLocal
        closeTimeLocal
        businessDaysStoT
        timezoneName
        timeDiff
        timezone {
          diffFromServerTime
          translation {
            value
          }
          diff
          name
          nameIANA
        }
        city {
          name
        }
      }
      lastRowChange
      ${INTRADAY_QUERY(1, dateRange)}
    }`;
};

// export const GET_SHARE_PERFORMANCE_HISTORY = (date) => {
//   return ` historicals(
//       where: { dateTime: { eq: "${date}T00:00:00.000Z" } }
//       order: { dateTime: DESC }
//       first: 1
//     ) {
//       nodes {
//         close
//       }
//     }`;
// };
export const GET_SHARE_PERFORMANCE_HISTORY = (duration) => {
  return `performance(period: ${duration}) {
      changePercentage
    }`;
};

export const GET_SHARE_PERFORMANCE = (ids, dateRange) => {
  return `
      instrumentByIds(ids: [${ids}], useCloud: true) {
        id
        companyCode
        currencyCode
        shareName            
        currentPrice {
          date
          last
          low
          open
          prevClose
          volume
          high
          change
          changePercentage
        }
        oneMonthChange: ${GET_SHARE_PERFORMANCE_HISTORY("ONE_MONTH")}
        threeMonthsChange: ${GET_SHARE_PERFORMANCE_HISTORY("THREE_MONTHS")}
        sixMonthsChange: ${GET_SHARE_PERFORMANCE_HISTORY("SIX_MONTHS")}
        oneYearChange: ${GET_SHARE_PERFORMANCE_HISTORY("ONE_YEAR")}
      }
`;
};

export const GET_ANALYST_RATING = (companyCode, date) => {
  return `
  company(code: "${companyCode}") {
    fundamental {
      analystRecommendation(showDataFrom: "${date}") {
        analystRatingBuy
        analystRatingHold
        analystRatingSell
        analystRatingStrongBuy
        analystRatingStrongSell
        date
      }
    }
  }
`;
};

export const GET_EARNINGS_REVENUE = (companyCode, dateRange) => {
  return `company(code: "${companyCode}") {
    fundamental {
      reported: incomeStatement(
        source: "financialmodelingprep"
        where: { period: { in: "Q1,Q2,Q3,Q4" } }
        order: { date: DESC }
        take: 4
      ) {
        items {
          calendarYear
          date
          period
          revenue
          eps
          reportedCurrency
          periodStart
          periodEnd
        }
      }
      expected: estimates(
        source: "tikr-primary"
        order: { date: DESC }
        where: {
          calculation: { eq: "median" }
          period: { in: "Q1,Q2,Q3,Q4" }
          year: { in: [2024, 2023] }
        }
      ) {
        items {
          calendarYear
          date
          periodEnd
          periodStart
          revenue
          period
          currency
          reportedCurrency
          calculation
        }
      }

      earnings_reported_annual: incomeStatement(
        source: "financialmodelingprep"
        order: { date: ASC }
        where: {
          period: { in: "FY" }
          year: { in: [${getYearsArray(dateRange?.currentYear, 5)}] }
        }
      ) {
        items {
          calendarYear
          date
          period
          revenue
          reportedCurrency
          periodStart
          periodEnd
        }
      }

      earnings_expected_annual: estimates(
        source: "tikr-primary"
        order: { date: ASC }
        where: {
          calculation: { eq: "median" }
          period: { in: "FY" }
          year: { in: [${getYearsArray(dateRange?.currentYear, 5)}] }
        }
      ) {
        items {
          calendarYear
          date
          periodEnd
          periodStart
          revenue
          period
          currency
          reportedCurrency
          calculation
        }
      }

      revenuereported: incomeStatement(
        source: "financialmodelingprep"
        order: { date: DESC }
        where: { period: { in: "FY" } }
        take: 1
      ) {
        items {
          revenue
          operatingExpenses
          operatingIncome
          incomeTaxExpense
          netIncome

          calendarYear
          date
          period
          costOfRevenue
          grossProfit
          otherExpenses
          reportedCurrency
          periodStart
          periodEnd
        }
      }
    }
  }`;
};

/** TODAY WIDGET DATA */
export const GET_TODAY_WIDGET_INFO_1 = (param) => {
  const dateRanges = getDateRanges();
  // instrumentsLastMarket: ${GET_INTRADAY_MAIN(param?.accessConfig?.shares, dateRanges?.yesterDayRange)}
  return `query {
   instruments: ${GET_INTRADAY_MAIN(
     param?.accessConfig?.shares,
     dateRanges?.todayRange
   )}
   sharePerformance: ${GET_SHARE_PERFORMANCE(
     param?.accessConfig?.shares,
     dateRanges
   )}
    dividends: ${GET_DIVIDENDS(
      param?.accessConfig?.shares,
      dateRanges?.currentYear
    )}
  }`;
};

export const GET_TODAY_WIDGET_INFO_2 = (param) => {
  const dateRanges = getDateRanges();
  return `query {
    ${GET_PEERS_QUERY(param, dateRanges)}    
    indices: ${GET_INTRADAY_MAIN(
      param?.accessConfig?.indices,
      dateRanges?.todayRange
    )}
  }`;
};

export const GET_TODAY_WIDGET_INFO_3 = (param) => {
  const dateRanges = getDateRanges();
  return `query {
    news: ${GET_COMPANY_ANNOUNCEMENT(param, 10)}
    analystRating: ${GET_ANALYST_RATING(
      param?.company,
      dateRanges?.startOfCurrentYear
    )}
    earningsRevenue: ${GET_EARNINGS_REVENUE(param?.company, dateRanges)}
  }`;
};

/** WEEKLY WIDGET DATA */
export const GET_WEEKLY_WIDGET_INFO = (param) => {
  const dateRanges = getDateRanges();
  return `query {
   instruments: instrumentByIds(ids: [${param?.accessConfig?.shares}]) {
      id
      shareName
      companyCode
      currencyCode
      symbol
      company {
        country
      }
      currentPrice {
        change
        changePercentage
        last
        high
        open
        low
        prevClose
        volume
      }
      market {
        abbreviation
        status {
          isOpened
        }
        city {
          name
        }
      }
      ${HISTORICALS_QUERY(dateRanges?.last7DaysRange)}
      lastRowChange
    }
    dividends: ${GET_DIVIDENDS(
      param?.accessConfig?.shares,
      dateRanges?.currentYear
    )}
    peers: ${GET_PEERS_COMPARISON(param, dateRanges)}
  }`;
};

/** MONTHLY WIDGET DATA */
export const GET_MONTHLY_WIDGET_INFO = (param) => {
  const dateRanges = getDateRanges();
  return `query {
   instruments: instrumentByIds(ids: [${param?.accessConfig?.shares}]) {
      id
      shareName
      companyCode
      currencyCode
      symbol
      company {
        country
      }
      currentPrice {
        change
        changePercentage
        last
        high
        open
        low
        prevClose
        volume
      }
      market {
        abbreviation
        status {
          isOpened
        }
        city {
          name
        }
      }
      ${INTRADAY_QUERY(1, dateRanges?.todayRange)}
      lastRowChange
    }
    dividends: ${GET_DIVIDENDS(
      param?.accessConfig?.shares,
      dateRanges?.currentYear
    )}
    peers: ${GET_PEERS_COMPARISON(param, dateRanges)}
  }`;
};

/** COMPANY ANNOUNCEMENTS */
export const GET_COMPANY_ANNOUNCEMENT = (param, count) => {
  return `
      company(code: "${param?.company}") {
        pressReleases( 
        order: { dateTime: DESC },
        where: { languageId: { eq: 32 }, messageType: { and: { id: { in: [4, 0] }, name: { neq: "N/A" } } }} 
        first: ${count}) {
          nodes {
            id
            title
            insertedDate
            dateTime
            hasAttachment
            messageTypeId
            
            languageId
            attachments(order: { order: DESC }) {
              nodes {
                fileName
                id
                isInBlob
                languageId
                location
                mime
                order
              }
            }
            messageType {
              name
            }
          }
        }
      }
  `;
};

export const GET_ANNOUNCEMENT_BY_DATE = (param) => {
  return `
    company(code: "${param?.company}") {
      pressReleases(
        order: { dateTime: DESC }, where: { dateTime: { gte: "${
          param.fromDate
        }", lte: "${
    param.toDate
  }" }, languageId: { eq: 32 }, messageType: { and: { id: { in: [4, 0] }, name: { neq: "N/A" } } } } ${
    param.limit ? ", first: 50" : ""
  }) {
        nodes {
          id
          title
          insertedDate
          dateTime
          hasAttachment
          messageTypeId
          languageId
          attachments(order: { order: DESC }) {
            nodes {
              fileName
              id
              isInBlob
              languageId
              location
              mime
              order
            }
          }
          messageType {
            name
          }
        }
      }
    }
  `;
};

export const GET_ANNOUNCEMENT_BY_ID = (param) => {
  return `query {
    pressrelease(id: ${param.id}) {
      id
      title
      insertedDate
      message
      dateTime
      hasAttachment
      messageTypeId
      languageId
      attachments(order: { order: DESC }) {
        nodes {
          fileName
          id
          isInBlob
          languageId
          location
          mime
          order
        }
      }
      messageType {
        name
      }
    }
  }`;
};

export const SIGNUP_USER = (param) => {
  return `
    mutation {
      signupUser(username: "${param.name}", email: "${param.email}", password: "${param.password}") {
        message
        success
        data {
          createdAt
          email
          isActive
          lastLogin
          passwordHash
          userId
          username
        }
      }
    }
  `;
};

export const LOGIN_USER = (param) => {
  return `
    mutation {
      loginUser(email: "${param.email}", password: "${param.password}") {
        message
        success
        data {
          createdAt
          email
          isActive
          lastLogin
          passwordHash
          userId
          username
        }
      }
    }
  `;
};

export const GET_ANNOUNCEMENT_QA = (param) => {
  return `
  query {
    userAnnouncement(announcement_Id: 0, external_Id: "${param.announcementId}", user_Id: ${param.userId}) { 
      postId: questionId
      userId
      user {
        userName: username
      }
      createdAt
      postedOn: timeAgo  
      highlightContent:highlightedText
      message:questionText
      replyContent:answers {
        userId
        user {
          userName: username
        }
        createdAt
        postedOn:timeAgo
        answerId
        message:answerText
      
      }
      likes:likesCount
      current_user_like_status:hasUserLiked 
    }
  }`;
};

export const UPDATE_ANNOUNCEMENT_QA = (param) => {
  return `
    mutation {
      addPost(
        addAnnouncementModel: {
          announcementId: "${param.announcementId}",
          highlightContent: "${param.highlightContent}",
          message: "${param.message}",
          questionId: ${param.questionId}
          userId: ${param.userId},
        }
      ) {
        message
        success
      }
    }
  `;
};

export const UPDATE_ANNOUNCEMENT_LIKE = (param) => {
  return `
    mutation {
      addLike( 
        addAnnouncementLikeModel: {
          announcementId: ${param.announcementId},
          like: ${param.like},
          userId: ${param.userId},
          externalId: "${param.externalId}",
          questionId: ${param.questionId},
        }
      ) {
        message
        success
      }
    }
  `;
};

export const GET_FINANCIAL_REPORT = (param) => {
  // ANNUAL REPORT
  return `
  query {
    company(code: "${param?.company}") {
          fundamental {
            reports(
              where: { ${
                param?.period && `reportTypeId: { in: [${param?.period}] },`
              } ${
    param?.year && `year: { eq: ${param?.year} },`
  } languageCode: { eq: "en" } }
   order: {
          year: DESC
          reportParentTypeOrder: ASC
          reportTypeOrder: ASC
          title: ASC
          languageCode: ASC
          languageName:  ASC
        }
            ) {
              nodes {
                fileLocation
                id
                languageCode
                languageName
                reportParentTypeDisplayName
                reportParentTypeId
                reportParentTypeOrder
                reportTypeId
                reportTypeName
                reportTypeOrder
                subTitle
                thumbnailFileLocation
                thumbnailFileLocationFull
                title
                uploadedDate
                uploadedDateUTC
                year
              }
            }
      }
    }
  }`;
};

import axios from "axios";
import Config from "../config";
import loggerService from "./logger.service";
import { getActiveCompany } from "../utils/storage";

// Request methods
const GET = "GET";
const POST = "POST";
const PUT = "PUT";
const DELETE = "DELETE";

/**
 * Set headers & base url
 */
export function getHttpHeader() {
  // Set default headers
  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "accessType": "Announcement"
  };

  return headers;
}

export const axiosBase = axios.create({
  baseURL: Config.authBaseUrl,
  headers: getHttpHeader(),
});

// Create an Axios request interceptor
axiosBase.interceptors.request.use(
  (config) => {
    config.headers["companycode"] = `${getActiveCompany()}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * Http request
 */
export const request = async (
  method,
  path,
  httpParams,
  body,
  disableLoader = false
) => {
  // Console request time
  consoleRequestResponseTime("request", Config.apiBaseUrl + "" + path);
  
  switch (method) {
    case "GET":
      return axiosBase
        .get(path, { params: httpParams })
        .then((response) => processResponseData("success", path, response))
        .catch((error) => {
          processResponseData("failure", path, error);
          throw error;
        });
    case "POST":
      return axiosBase
        .post(path, body, { params: httpParams })
        .then((response) => processResponseData("success", path, response))
        .catch((error) => {
          processResponseData("failure", path, error);
          throw error;
        });
    case "PUT":
      return axiosBase
        .put(path, body, { params: httpParams })
        .then((response) => processResponseData("success", path, response))
        .catch((error) => {
          processResponseData("failure", path, error);
          throw error;
        });
    default:
      throw new Error(`Unsupported request method: ${method}`);
  }
};
/**
 * Process the response data
 */
export const processResponseData = (type, path, data, failureMsg) => {
  // If success and data is object
  if (type === "success") {
    // data = convertNulltoEmpty(data);
    if (Config.trackHttpResponseInConsole) {
      loggerService.showLog("Response Success");
      loggerService.showLog(["Request Url", Config.apiBaseUrl + "" + path]);
      loggerService.showLog(["Body", data]);
    }
    return data;
  } else {
    if (Config.trackHttpResponseInConsole) {
      loggerService.showLog("Response Failure");
      loggerService.showLog(["Url", Config.apiBaseUrl + "" + path]);
      loggerService.showLog(["Body", data]);
    }

    /**
     * Show error msg if
     * 1. Message available in service
     * 2. Otherwise show custom error from each service request
     * 3. Otherwise, show default message 'Service Failure'
     */
    // Need to Confirm params
    console.log(failureMsg);
  }
};

/**
 * Convert json null to empty write console
 */
export const convertNulltoEmpty = (data) => {
  let stringifyData = JSON.stringify(data).replace(/null/i, '""');
  stringifyData = stringifyData.replace(/null/g, '""');
  const json = JSON.parse(stringifyData);
  return json;
};

/**
 * Request / Response Time Tracker
 */
const consoleRequestResponseTime = (type, url) => {
  if (Config.trackHttpTimeInConsole) {
    if (type === "request") {
      console.log("Request Url", url);
      console.log("Time Started", new Date());
    } else {
      console.log("Response Url", url);
      console.log("Time Ended", new Date());
    }
  }
};

export default {
  GET: (path, ...props) => request(GET, path, ...props),
  POST: (path, ...props) => request(POST, path, props.params, ...props),
  PUT: (path, ...props) => request(PUT, path, props.params, ...props),
};
